import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	separator: {
		display: 'flex', alignItems: 'center'
	},
	separatorLine: {
		flex: 1,  borderBottom: '2px solid', borderBottomColor: theme.palette.primary.main,
	},
	separatorTitle: {
		color: theme.palette.primary.main,
		border: '2px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: '4px',
		fontWeight: 700,
		fontSize: '20px',
		padding: theme.spacing(0.5, 2),
	},
	separatorTitleFull: {
		margin: theme.spacing(1, 0),
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		border: '2px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: '4px',
		fontSize: '1em',
		padding: theme.spacing(0.5, 2),
	}
}));

export default function Divider(props) {	
	const classes = useStyles();

	return (
        <div className={classes.separator} >
            <div className={classes.separatorLine} />
            {props.title && <DividerText title={props.title}></DividerText>}
            <div className={classes.separatorLine} />
        </div>
	);
}

export const DividerText = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.separatorTitle}>
			{props.title}
		</div>
	);
}

export const DividerTextFull = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.separatorTitleFull}>
			{props.title}
		</div>
	);
}