import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  cardContent: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        width: '349px',
    },
  },
  descriptionHTML: {
    display: 'flex',
    flex: '1 0 auto',
    height: '0px',
    overflow: 'scroll',
    margin: theme.spacing(1, 0)
  },
  cardMedia: {
    [theme.breakpoints.down('sm')]: {
        width: '0',
    },
    [theme.breakpoints.up('sm')]: {
        width: '296px',
    },
  }
}));

export default function ProductCardBig(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
        <CardMedia
            className={classes.cardMedia}
            image={props.item.image}
            title={props.item.label}
            />
        <CardContent className={classes.cardContent}>
            <Typography component="h5" variant="h5" color="primary">
                {props.item.label}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">{props.item.shortDescription}</Typography>
            <div className={classes.descriptionHTML}>
                <div dangerouslySetInnerHTML={{__html: props.item.description}} />
            </div>
            <Button className={classes.action} onClick={props.onClick} color="primary" variant="contained">{`${t('AGGIUNGI ALL\'ORDINE PER')} ${props.item.price.label}`}</Button>
        </CardContent>
    </Card>
  );
}