import React from 'react';
import Items from './pages/Items';
import Menu from './pages/Menu';
import Home from './pages/Home';
import Payment from './pages/Payment';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
	palette: {
		common: {
		black: "#000",
		white: "#fff",
		red: '#d32f2f'
		},
		primary: {
		main: '#086d40',
		},
		secondary: {
		main: '#a1d97b',
		},
		textPrimary: {
		main: "#3e4043" // messo a mano nel template
		}
	},
	typography: {
		fontFamily: '"Montserrat"',
		subtitle1: {
		fontSize: "16px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.75,
		letterSpacing: "0.09px"
		},
		h5: {
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.33",
		letterSpacing: "normal"
		}
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router
				basename={'#'}>
				<Switch>
					<Route exact path={`/:UUID`}>
						<Items />
					</Route>
					<Route exact path={`/menu/:UUID`}>
						<Menu />
					</Route>
					<Route exact path={`/payment/loading`}>
						<Payment loading={true} />
					</Route>
					<Route exact path={`/:UUID/order/:ID/payment/success`}>
						<Payment success={true}/>
					</Route>
					<Route exact path={`/:UUID/order/:ID/payment/failure`}>
						<Payment success={false}/>
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</Router>
		</ThemeProvider>
	);
}

export default App;