import React from 'react';
import AppBarMU from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 700
  },
}));

export default function AppBar() {
  const classes = useStyles();

  return (
    <AppBarMU position="static">
        <Toolbar>
            <Typography className={classes.title} variant="h4" noWrap>FOORBAN</Typography>
        </Toolbar>
    </AppBarMU>
  );
}