import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useParams } from 'react-router-dom';
import AppBar from '../components/AppBar';
import Message from '../components/Message';
import { useTranslation } from 'react-i18next';
import LoopIcon from '@material-ui/icons/Loop';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';

export default function Payment(props) {
	let { UUID, ID} = useParams();
	const { t } = useTranslation();

	if(props.loading || !UUID) {
		return (
			<main>
				<CssBaseline />
				<AppBar />
				<Message
					link={false}
					message={`${t('PAGAMENTO IN CORSO')}`}
					icon={<LoopIcon style={{ fontSize: 40 }}/>}>	
				</Message>
			</main>
		);
	}
	if(props.success) {
		return (
			<main>
				<CssBaseline />
				<AppBar />
				<Message
					color={'primary'}
					link={`/${UUID}`}
					linkText={t('TORNA AL NEGOZIO')}
					message={`Il Frigo adesso è sbloccato, ritira il tuo ordine aprendo il frigo!`}
					icon={props.success ? <CheckIcon style={{ fontSize: 40 }} /> : <CloseIcon style={{ fontSize: 40 }} />}>
					<Typography style={{marginTop: '8px'}}color="inherit" variant="h5">
						(la maniglia è a sinistra <span role="img" aria-label="Winking Face Emoji">😉</span>)
					</Typography>
				</Message>
			</main>
		);
	}
	else {
		return (
			<main>
				<CssBaseline />
				<AppBar />
				<Message
					color={'error'}
					link={`/${UUID}`}
					linkText={t('TORNA AL NEGOZIO')}
					message={`${t('PAGAMENTO ORDINE')} #${ID} ${t('FALLITO')}`}
					icon={<CloseIcon style={{ fontSize: 40 }} />}>
				</Message>
			</main>
		);
	}

}