import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  it: {
    translation: {
        "LO VOGLIO": "LO VOGLIO",
        "VEDI INGREDIENTI": "VEDI INGREDIENTI",
        "TORNA AL NEGOZIO": "TORNA AL NEGOZIO",
        "AGGIUNGI ALL'ORDINE PER": "AGGIUNGI ALL'ORDINE PER",
        "TORNA ALL'ACCESSO": "TORNA ALL'ACCESSO",
        "NEGOZIO CHIUSO": "NEGOZIO CHIUSO",
        "CARICAMENTO": "CARICAMENTO",
        "ERRORE DURANTE IL CARICAMENTO": "ERRORE DURANTE IL CARICAMENTO",
        "PAGAMENTO ORDINE": "PAGAMENTO ORDINE",
        "COMPLETATO": "COMPLETATO",
        "FALLITO": "FALLITO",
        "NEGOZIO NON TROVATO": "NEGOZIO NON TROVATO",
        "ACCEDI AL NEGOZIO": "ACCEDI AL NEGOZIO",
        "VAI AL NEGOZIO": "VAI AL NEGOZIO",
        "URL NEGOZIO": "URL NEGOZIO",
        "PAGAMENTO IN CORSO": "PAGAMENTO IN CORSO",
        "MENU NON TROVATO": "MENU NON TROVATO"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "it",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;