import { createSlice } from '@reduxjs/toolkit';

export const shop = createSlice({
  name: 'shop',
  initialState: {
    loading: true,
    error: false,
    UUID: null,
    shopStatus: null,
    data: {
      categories: [],
      items: []
    }
  },
  reducers: {
    updateShopData: (state, action) => {
      state.shopStatus = action.payload.result.shopStatus;
      state.data.categories = action.payload.result.categories.sort((a, b) => {
        if (a.displayOrder < b.displayOrder) {
            return -1;
        }
        if (b.displayOrder < a.displayOrder) {
            return 1;
        }
        return 0;
      });
      state.data.items = action.payload.result.items;
      state.UUID = action.payload.UUID;
    },
    updateShopUUID: (state, action) => {
      state.UUID = action.UUID
    },
    resetShopData: (state, action) => {
      state.UUID =  {
        loading: true,
        error: false,
        UUID: null,
        shopStatus: null,
        data: {
          categories: [],
          items: []
        }
      }
    }, 
  },
});

export const { updateShopData, resetShopData } = shop.actions;

export const currentShop = state => state.shop.UUID;
export const categoriesShop = state => state.shop.data.categories;
export const statusShop = state => state.shop.shopStatus;
export const itemsShop = state => state.shop.data.items;
export const shopState = state => state.shop;

export const itemsMenuNews = state => {
  const items = state.shop.data.items
    .filter(item => {
      return item.new && item.showInScreenMenu
    })
    .filter((item, itemKey) => {
      return itemKey < 11
    })

  return items;
};

export const itemsMenu = state => {
  const items = state.shop.data.items
    .filter(item => {
      return !item.new && item.showInScreenMenu
    })
    .filter((item, itemKey) => {
      return itemKey < 11
    })
  return items;
};

export default shop.reducer;