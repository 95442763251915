import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { resetShopData } from '../app/shopReducer';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { useTranslation } from 'react-i18next';

function Copyright() {
  return (
	<Typography variant="body2" color="textSecondary" align="center">
	  {'Copyright © '}
	  <Link color="inherit" href="https://www.foorban.com/">
		Foorban
	  </Link>{' '}
	  {new Date().getFullYear()}
	  {'.'}
	</Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
	height: '100vh',
  },
  image: {
	backgroundImage: 'url(https://foorban.cloud.mia-platform.eu/files/download/3284888d-b430-4ad0-a8df-ce60bd572422.jpg)',
	backgroundRepeat: 'no-repeat',
	backgroundColor:
	  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
	backgroundSize: 'cover',
	backgroundPosition: 'center',
  },
  paper: {
	margin: theme.spacing(8, 4),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
  },
  avatar: {
	margin: theme.spacing(1),
	backgroundColor: theme.palette.primary.main,
  },
  form: {
	width: '100%', // Fix IE 11 issue.
	marginTop: theme.spacing(1),
  },
  submit: {
	margin: theme.spacing(3, 0, 2),
  },
}));

export default function Home() {
  	const classes = useStyles();
	let [url, setUrl] = useState('');
	let history = useHistory();
	let urlFromLocalStorage = localStorage.getItem('url');
	const dispatch = useDispatch();
	const { t } = useTranslation();

	if(urlFromLocalStorage) {
		dispatch(resetShopData);
	}

  return (
	<Grid container component="main" className={classes.root}>
	  <CssBaseline />
	  <Grid item xs={false} sm={4} md={7} className={classes.image} />
	  <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
		<div className={classes.paper}>
		  <Avatar className={classes.avatar}>
		  	<StorefrontIcon></StorefrontIcon>
		  </Avatar>
		  <Typography component="h1" variant="h5">
		  	{t('ACCEDI AL NEGOZIO')}
		  </Typography>
		  <div className={classes.form}>
			<TextField
				value={url}
				onChange={e => setUrl(e.target.value)}
			  variant="outlined"
			  margin="normal"
			  fullWidth
			  id="link"
			  label={t('URL NEGOZIO')}
			  name="shop"
			  autoFocus
			/>
			<Button
			  type="submit"
			  fullWidth
			  variant="contained"
			  color="primary"
			  className={classes.submit}
			onClick={() => {
				if(url && url.length > 0) {
					localStorage.setItem('url', url);
					history.push(`/${url}`)
				}
			}}
			>
			  {t('VAI AL NEGOZIO')}
			</Button>
			<Box mt={5}>
			  <Copyright />
			</Box>
		  </div>
		</div>
	  </Grid>
	</Grid>
  );
}