import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarColorPrimary: {
    width: '80px',
    height: '80px',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  avatarColorError: {
    width: '80px',
    height: '80px',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.red,
  },
  back: {
    margin: theme.spacing(8, 0, 0),
  },
  message: {
    margin: theme.spacing(2, 0, 0),
  }
}));

function Back(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <Button size='large' className={classes.back} component={ RouterLink } to={props.link ? props.link : `/`} variant="contained" color="primary">
        {props.linkText ? props.linkText : t('TORNA ALL\'ACCESSO')}
      </Button>
    )
}
  
const Message = (props) => {
    const classes = useStyles();
    let avatarClass;

    if(props.color !== 'primary') {
      avatarClass = classes.avatarColorError;
    } else {
      avatarClass = classes.avatarColorPrimary;
    }

    return (
        <Container>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={avatarClass}>
                    {props.icon}
                </Avatar>
                <Typography className={classes.message} color="inherit" component="h1" variant="h5">
                    {props.message}
                </Typography>
                {props.children}
                {props.link ? <Back link={props.link} linkText={props.linkText}></Back> : null}
            </div>
        </Container>
    );
}

Message.defaultProps = {
  color: 'primary',
  link: '/'
}

export default Message;