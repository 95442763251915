import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateShopData, categoriesShop, statusShop, itemsShop} from '../app/shopReducer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '../components/AppBar';
import ProductCard from '../components/ProductCard';
import Message from '../components/Message';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LoopIcon from '@material-ui/icons/Loop';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	heroTitle: {
		padding: theme.spacing(3, 0),
	},
	container: {
		padding: theme.spacing(0, 3),
	}
}));

const TIMEOUT = 120000;

export default function Items() {
	let history = useHistory();
	let { UUID } = useParams();
	const { t } = useTranslation();
	const categories = useSelector(categoriesShop);
	const items = useSelector(itemsShop);
	const status = useSelector(statusShop);
	
	const dispatch = useDispatch();
	
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let timer = setTimeout(() => {
			window.location.reload()
		}, TIMEOUT);

		const procrastinateTimer = event => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				window.location.reload()
			}, TIMEOUT);
		};

		window.addEventListener('touchstart', procrastinateTimer);
		window.addEventListener('scroll', procrastinateTimer);

		return () => {
			window.removeEventListener('touchstart', procrastinateTimer);
			window.removeEventListener('scroll', procrastinateTimer);
			clearTimeout(timer);
		};
	});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_WAREHOUSE_API}/api/v1/impulse/${UUID}/items`)
		.then(res => res.json())
		.then(result => {
			if(result.statusCode && result.statusCode !== 200) {
				setLoading(false);
				setError(result.statusCode);
				return;
			}
			setLoading(false);
			dispatch(updateShopData({result, UUID}));
		},
		error => {
			setLoading(false);
			setError(500);
		}
		);
	}, [UUID, dispatch, error]);
	
	const classes = useStyles();

	return (
		<main>
			<CssBaseline />
			<AppBar />
			{loading && <Message message={`${t('CARICAMENTO')}`} icon={<LoopIcon style={{ fontSize: 40 }} />} link={null}></Message>}
			{error && <Message color="error" message={error === 404 ? `${t('NEGOZIO NON TROVATO')}` : `${t('ERRORE DURANTE IL CARICAMENTO')}`} icon={<WarningIcon style={{ fontSize: 40 }} />}></Message>}
			{!loading && !error && status === 'Close' && <Message message={`${t('NEGOZIO CHIUSO')}`} icon={<LockOutlinedIcon style={{ fontSize: 40 }}/>} link={null}></Message>}
			{!loading && !error && status === 'Open' &&  categories.map(category => (
				<div className={classes.container} key={category.id}>
					<Typography variant="h5" color="inherit" className={classes.heroTitle}>
						{category.label.toUpperCase()}
					</Typography>

					<Grid container spacing={4}>
						{items
							.filter(item => {
								return item.categoryId === category.id
							})
							.sort((a, b) => {
								if (a.displayOrder < b.displayOrder) {
									return -1;
								}
								if (b.displayOrder < a.displayOrder) {
									return 1;
								}
								return 0;
							})
							.map((item) => (
								<ProductCard key={item.id} item={item} onClick={() => {
									history.push('/payment/loading');
									window.location.href = `${process.env.REACT_APP_WAREHOUSE_API}/api/v1/impulse/${UUID}/order/purchase/${item.id}`
								}}/>
							))
						}
					</Grid>
				</div>
			))}
	</main>
	);
}