import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CardActionArea } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ProductCardBig from './ProductCardBig';

const useStyles = makeStyles((theme) => ({
    cardMedia: {
        paddingTop: '100%'
    },
    cardContent: {
        height: '56px',
        padding: theme.spacing(1, 2),
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cardActions: {
        flexDirection: 'column',
        padding: theme.spacing(1, 0, 0)
    },
    cardAction: {
        margin: theme.spacing(0, 0, 1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        padding: theme.spacing(1)
    }
}));

const DialogContent = withStyles((theme) => ({
    root: {
        height: '393px',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '645px',
        },
        padding: theme.spacing(0)
    }
}))(MuiDialogContent);

const DialogTitle = ((props) => {
    const classes = useStyles();

    return (
        <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
        </IconButton>
    );
});
export default function ProductCard(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
        <Grid item xs={6} sm={4} md={3}>
            <Card>
                <CardActionArea onClick={handleClickOpen}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={props.item.image}
                        title={props.item.label}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" color="textPrimary">{props.item.label}</Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions disableSpacing={true} className={classes.cardActions}>
                    <Button onClick={props.onClick} className={classes.cardAction} color="primary" variant="contained">{`${t('LO VOGLIO')} - ${props.item.price.label}`}</Button>
                    <Button onClick={handleClickOpen} className={classes.cardAction} color="primary" size="small">{t('VEDI INGREDIENTI')}</Button>
                </CardActions>
            </Card>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} />
                <DialogContent>
                    <ProductCardBig onClick={props.onClick} item={props.item}></ProductCardBig>
                </DialogContent>
            </Dialog>
        </Grid>
  );
}