import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@material-ui/core';
import {DividerTextFull} from './Divider';
import QRCode from 'react-qr-code';

import { useWindowSize } from '../utils';
import { IconFromDiet } from './Diet';

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: "90%",
	},
	cardContent: {
		height: "80px",
		padding: theme.spacing(1, 2),
		overflow: "hidden",
		//display: 'flex',
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	cardTitle: {
		fontSize: "15px",
		display: "-webkit-box",
		WebkitLineClamp: 3,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	cardActions: {
		display: "flex",
		flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    gap: "5px",
		padding: theme.spacing(1, 0, 0),
	},
	cardAction: {
		margin: theme.spacing(0, 0, 1),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1),
	},
	qrcode: {
		display: "flex",
		flex: 1,
		justifyContent: "center",
		paddingBottom: "16px",
	},
}));

export default function MenuProductCard(props) {
  const classes = useStyles();

  const size = useWindowSize();
  const horizzontal = size.width > size.height

  return (
        <Grid item xs={horizzontal ? 2 : 4} sm={horizzontal ? 2 : 4} md={horizzontal ? 2 : 4} lg={horizzontal ? 2 : 4} xl={horizzontal ? 2 : 4}>
            <Card elevation={0} style={{backgroundColor: 'transparent'}}>
            {props.onlyTitle && props.item && props.item.link && <div className={classes.qrcode}><QRCode size={128} value={props.item.link} bgColor="rgba(236, 201, 138, 1)" /></div>}
                <CardActionArea>
                    {!props.onlyTitle && <CardMedia
                        style={{maxWidth: '95%'}}
                        className={classes.cardMedia}
                        image={props.item.imageNoBackground}
                        title={props.item.label}
                    />}
                    {props.onlyTitle && <Typography style={{textAlign: 'center', textTransform: 'uppercase', paddingTop: '8px', fontWeight: 700, fontSize: '1rem' }} color="primary">{props.item.label}</Typography>}
                    {!props.onlyTitle && <CardContent className={classes.cardContent}>
                        <Typography style={{textTransform: 'uppercase', width: '100%'}} className={classes.cardTitle} variant="subtitle1" color="textPrimary">{props.item.label}</Typography>
                    </CardContent>}
                </CardActionArea>
                {!props.onlyTitle && <CardActions disableSpacing={true} className={classes.cardActions}>
                    {props.item.edible && props.item.edible.diet && <IconFromDiet diet={props.item.edible.diet} size="regular"/>}
                    <DividerTextFull title={`${props.item.price.label}`}></DividerTextFull>
                {/* <Button onClick={props.onClick} className={classes.cardAction} color="primary" variant="contained">{`${props.item.price.label}`}</Button> */}
                </CardActions>}
            </Card>
        </Grid>
  );
}